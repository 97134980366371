import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery } from "app/utils/constants/common";
import {
  BlockedMealsList,
  CreateBlockedListPayload,
  PaginationInterfaceLF,
  UpdateBlockedListPayload,
} from "app/types";
import { RootState } from "../reducers/hooks";
import {
  createBlockedListAPI,
  getBlockedListAPI,
  updateBlockedListAPI,
} from "app/services/meals";

interface InitialState {
  blockedMealsList: BlockedMealsList[];
  blockedMealSelectionListPagination: PaginationInterfaceLF;
  blockedMealSelectionListRowsPerPAge: number;
  isBlockedMealsListLoading: boolean;
}

const initialState: InitialState = {
  blockedMealsList: [],
  blockedMealSelectionListRowsPerPAge: 20,
  blockedMealSelectionListPagination: null,
  isBlockedMealsListLoading: false,
};

export const mealsManagerSlice = createSlice({
  name: "mealsManager",
  initialState,
  reducers: {
    setBlockedMealsList: (
      state,
      action: PayloadAction<{
        list: BlockedMealsList[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.blockedMealsList = action.payload.list;
      state.blockedMealSelectionListPagination = action.payload.pagination;
    },

    setBlockedMealSelectionListLoading: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.isBlockedMealsListLoading = action.payload;
    },
    setBlockedMealsListRowPerPage: (state, action: PayloadAction<number>) => {
      state.blockedMealSelectionListRowsPerPAge = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setBlockedMealsList,
  setBlockedMealsListRowPerPage,
  setBlockedMealSelectionListLoading,
} = mealsManagerSlice.actions;

export default mealsManagerSlice.reducer;

export const getBlockedListAction = (
  { continutationToken } = {
    continutationToken: null,
  },
  storeId: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { blockedMealSelectionListRowsPerPAge } =
        getState().mealsManagerSlice;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: blockedMealSelectionListRowsPerPAge,
            continutationToken,
          },
        });
      dispatch(setBlockedMealSelectionListLoading(true));
      const { data } = await getBlockedListAPI(storeId, query);
      dispatch(setBlockedMealSelectionListLoading(false));
      const pagination = data.pagination;
      dispatch(
        setBlockedMealsList({
          list: data.data,
          pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(setBlockedMealSelectionListLoading(false));
      dispatch(alertMessage(message, "error"));
    }
  };
};
export const createBlockListAction =
  (storeId: number, payload: CreateBlockedListPayload) =>
  async (dispatch: AppDispatch) => {
    try {
      const { data } = await createBlockedListAPI(storeId, payload);
      if (data.data) {
        dispatch(alertMessage("Created successfully", "success"));
        // We refresh the  data
        dispatch(getBlockedListAction({ continutationToken: null }, storeId));
      }
    } catch (error) {
      dispatch(alertMessage("Something went wrong", "error"));
    }
  };

export const updateBlockListAction =
  (storeId: number, payload: UpdateBlockedListPayload) =>
  async (dispatch: AppDispatch) => {
    try {
      await updateBlockedListAPI(storeId, payload);
      // We refresh the  data
      dispatch(getBlockedListAction({ continutationToken: null }, storeId));
      dispatch(alertMessage("Updated successfully", "success"));
    } catch (error) {
      dispatch(alertMessage("Error updating the status", "error"));
    }
  };
