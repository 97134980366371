import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { AppDispatch } from "../store";
import { alertMessage } from "../actions/common";
import { errorHandler } from "app/shared/Service/errorHandler";
import { createQuery } from "app/utils/constants/common";
import { PaginationInterfaceLF } from "app/types";
import { RootState } from "../reducers/hooks";
import {
  getMealHistoryApi,
  getPlannedOrderLogsApi,
  getRefundRequestsApi,
  updateRefundRequestStatusApi,
  getSubscriptionLogsApi,
  getClientPreferenceLogsApi
} from "app/services/subscriptions";
import {
  MealHistory,
  PlannedOrderLog,
  RefundReasonDetails,
  RefundRequest,
  RefundStatus,
  RefundStatusDetails,
  SubscriptionLog,
  ClientPreferenceLogs
} from "app/types/subscriptionsManager";

interface InitialState {
  plannedOrderLogs: PlannedOrderLog[];
  subscriptionLogs: SubscriptionLog[];
  clientPreferenceLogs: ClientPreferenceLogs[];
  clientPreferenceLogsPagination: PaginationInterfaceLF;
  subscriptionLogsPagination: PaginationInterfaceLF;
  plannedOrderLogsPagination: PaginationInterfaceLF;
  plannedOrderLogListRowPerPage: number;
  subscriptionLogsListRowPerPage: number;
  clientPreferenceLogListRowPerPage: number;
  isPlannedOrderLogLoading: boolean;
  mealHistoryItems: MealHistory[];
  isMealHistoryLoading: boolean;
  isSubscriptionHistoryLoading: boolean;
  refundRequestItems: RefundRequest[];
  isRefundRequestsLoading: boolean;
  isSubscriptionLogsLoading: boolean;
  isClientPreferenceLogsLoading: boolean;
}

const initialState: InitialState = {
  plannedOrderLogs: [],
  subscriptionLogs: [],
  clientPreferenceLogs: [],
  clientPreferenceLogsPagination: null,
  subscriptionLogsPagination: null,
  plannedOrderLogsPagination: null,
  plannedOrderLogListRowPerPage: 20,
  subscriptionLogsListRowPerPage: 20,
  clientPreferenceLogListRowPerPage: 20,
  isPlannedOrderLogLoading: false,
  mealHistoryItems: [],
  isMealHistoryLoading: false,
  isSubscriptionHistoryLoading: false,
  refundRequestItems: [],
  isRefundRequestsLoading: false,
  isSubscriptionLogsLoading: false,
  isClientPreferenceLogsLoading: false
};

export const subscriptionsManagerSlice = createSlice({
  name: "subscriptionsManager",
  initialState,
  reducers: {
    setPlannedOrderLogs: (
      state,
      action: PayloadAction<{
        logs: PlannedOrderLog[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.plannedOrderLogs = action.payload.logs;
      state.plannedOrderLogsPagination = action.payload.pagination;
    },
    setSubscriptionLogs: (
      state,
      action: PayloadAction<{
        logs: SubscriptionLog[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.subscriptionLogs = action.payload.logs;
      state.subscriptionLogsPagination = action.payload.pagination;
    },

    setPlannedOrderLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.isPlannedOrderLogLoading = action.payload;
    },
    setSubscriptionLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.isSubscriptionLogsLoading = action.payload;
    },
    setClientPreferenceLogs: (
      state,
      action: PayloadAction<{
        logs: ClientPreferenceLogs[];
        pagination: PaginationInterfaceLF;
      }>
    ) => {
      state.clientPreferenceLogs = action.payload.logs;
      state.clientPreferenceLogsPagination = action.payload.pagination;
    },
    setClientPreferenceLogsLoading: (state, action: PayloadAction<boolean>) => {
      state.isClientPreferenceLogsLoading = action.payload;
    },
    setPlannedOrderLogListRowPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.plannedOrderLogListRowPerPage = action.payload;
    },
    setSubscriptionLogListRowPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.subscriptionLogsListRowPerPage = action.payload;
    },
    setClientPreferenceLogListRowPerPage: (
      state,
      action: PayloadAction<number>
    ) => {
      state.clientPreferenceLogListRowPerPage = action.payload;
    },

    setMealHistoryItems: (
      state,
      action: PayloadAction<{
        items: MealHistory[];
      }>
    ) => {
      state.mealHistoryItems = action.payload.items;
    },
    setMealHistoryLoading: (state, action: PayloadAction<boolean>) => {
      state.isMealHistoryLoading = action.payload;
    },
    setRefundRequestItems: (
      state,
      action: PayloadAction<{
        items: RefundRequest[];
      }>
    ) => {
      state.refundRequestItems = action.payload.items;
    },
    setRefundRequestsLoading: (state, action: PayloadAction<boolean>) => {
      state.isRefundRequestsLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setPlannedOrderLogs,
  setSubscriptionLogs,
  setClientPreferenceLogs,
  setPlannedOrderLogsLoading,
  setPlannedOrderLogListRowPerPage,
  setClientPreferenceLogListRowPerPage,
  setSubscriptionLogsLoading,
  setClientPreferenceLogsLoading,
  setSubscriptionLogListRowPerPage,
  setMealHistoryItems,
  setMealHistoryLoading,
  setRefundRequestItems,
  setRefundRequestsLoading,
} = subscriptionsManagerSlice.actions;

export default subscriptionsManagerSlice.reducer;

export const getPlannedOrderLogsAction = (
  { continutationToken } = {
    continutationToken: null,
  },
  plannedOrdersId: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { plannedOrderLogListRowPerPage } = getState().subscriptionsManager;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: plannedOrderLogListRowPerPage,
            continutationToken,
          },
        });
      dispatch(setPlannedOrderLogsLoading(true));
      const { data } = await getPlannedOrderLogsApi(plannedOrdersId, query);
      dispatch(setPlannedOrderLogsLoading(false));
      const logs = data.data;
      const users = data.included.users;
      const pagination = data.pagination;

      // Update the logs to add the user object to log object in case of "userId" != 0
      const updatedLogs = logs.map((log) => {
        const user = users.find((user) => user.id === log.userId);
        return {
          ...log,
          user,
        };
      });

      // Dispatch the updated logs to the state
      dispatch(
        setPlannedOrderLogs({
          logs: updatedLogs,
          pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getSubscriptionLogsAction = (
  { continutationToken } = {
    continutationToken: null,
  },
  subscriptionId: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { subscriptionLogsListRowPerPage } =
        getState().subscriptionsManager;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            limit: subscriptionLogsListRowPerPage,
            continutationToken,
          },
        });
      dispatch(setSubscriptionLogsLoading(true));
      const { data } = await getSubscriptionLogsApi(subscriptionId, query);
      dispatch(setSubscriptionLogsLoading(false));
      const logs = data.data;
      const users = data.included.users;
      const pagination = data.pagination;

      // Update the logs to add the user object to log object in case of "userId" != 0
      const updatedLogs = logs.map((log) => {
        const user = users.find((user) => user.id === log.userId);
        return {
          ...log,
          user,
        };
      });

      // Dispatch the updated logs to the state
      dispatch(
        setSubscriptionLogs({
          logs: updatedLogs,
          pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getClientPreferenceLogsAction = (
  { continutationToken } = {
    continutationToken: null,
  },
  clientId: number
) => {
  return async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
      const { clientPreferenceLogListRowPerPage } =
        getState().subscriptionsManager;
      let query = "";
      query =
        "?" +
        createQuery({
          filter: {
            action: "ClientPreferennce",
            limit: clientPreferenceLogListRowPerPage,
            continutationToken,
          },
        });
      dispatch(setClientPreferenceLogsLoading(true));
      const { data } = await getClientPreferenceLogsApi(clientId, query);
      dispatch(setClientPreferenceLogsLoading(false));
      const logs = data.data;
      const users = data.included.users;
      const pagination = data.pagination;

      // Update the logs to add the user object to log object in case of "userId" != 0
      const updatedLogs = logs.map((log) => {
        const user = users.find((user) => user.id === log.userId);
        return {
          ...log,
          user,
        };
      });

      // Dispatch the updated logs to the state
      dispatch(
        setClientPreferenceLogs({
          logs: updatedLogs,
          pagination,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getMealHistoryAction = (plannedOrdersId: number) => {
  return async (dispatch: AppDispatch) => {
    try {
      dispatch(setMealHistoryLoading(true));
      const { data } = await getMealHistoryApi(plannedOrdersId);
      dispatch(setMealHistoryLoading(false));
      const mealHistoryItems = data.data;
      const users = data.included.users;
      const meals = data.included.meals;

      // Update the mealHistoryItems to add the user & meal object to each item
      const updatedMealHistoryItems = mealHistoryItems.map((item) => {
        const user = users.find((user) => user.id === item.userId);
        const mealInfo = meals.find((meal) => meal.mealId === item.mealId);
        return {
          ...item,
          user,
          mealInfo,
        };
      });

      // Dispatch the updated mealHistoryItems to the state
      dispatch(
        setMealHistoryItems({
          items: updatedMealHistoryItems,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

export const getRefundRequestsAction = (
  clientId: number,
  subscriptionId: number
) => {
  return async (dispatch: AppDispatch) => {
    try {
      const query =
        "?" +
        createQuery({
          filter: {
            referenceId: subscriptionId,
          },
        });

      dispatch(setRefundRequestsLoading(true));
      const { data } = await getRefundRequestsApi(clientId, query);
      dispatch(setRefundRequestsLoading(false));
      const refundRequestItems = data.data;
      const users = data.included.users;
      const currencies = data.included.currencies;

      // Update the refundRequestItems to add the user & price object to each item
      const updatedRefundRequestItems = refundRequestItems.map((item) => {
        const user = users?.find((user) => user.id === item?.createdByUserId);
        const currency = currencies.find(
          (currency) => currency.currencyId === item.currencyId
        );

        const reasonDetails: RefundReasonDetails = {
          reason: item.reason,
          message: item.reasonMessage,
        };

        const statusDetails: RefundStatusDetails = {
          status: item.status,
          explaination:
            item.status === "Rejected" ? item.rejectionExplaination : null,
        };
        const price = {
          amount: item.amount,
          code: currency?.localizedCode || currency?.code,
        };
        return {
          ...item,
          user,
          price,
          reasonDetails,
          statusDetails,
        };
      });

      // Dispatch the updated refundRequestItems to the state
      dispatch(
        setRefundRequestItems({
          items: updatedRefundRequestItems,
        })
      );
    } catch (error) {
      const message = errorHandler(error);
      dispatch(alertMessage(message, "error"));
    }
  };
};

type UpdateRefundRequestStatusPayload = {
  clientId: number;
  subscriptionId: number;
  refundRequestId: number;
  apiBody: {
    status: RefundStatus;
    RejectionExplaination?: string;
    PaymentMethod?: string;
  };

  successCallback: () => void;
};
export const updateRefundRequestStatusAction =
  ({
    clientId,
    subscriptionId,
    refundRequestId,
    apiBody,
    successCallback,
  }: UpdateRefundRequestStatusPayload) =>
    async (dispatch: AppDispatch) => {
      // Update refund request status
      try {
        const { data } = await updateRefundRequestStatusApi({
          clientId,
          refundRequestId,
          apiBody,
        });

        if (data.data.message) {
          // We refresh the RefundRequests data
          dispatch(getRefundRequestsAction(clientId, subscriptionId));
          successCallback();
        }
      } catch (error) {
        dispatch(alertMessage("error", "Error updating refund request status"));
      }
    };
