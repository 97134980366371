import {
  configurationsProduction,
  configurationsStaging,
  BackendConfigurations,
  BackendCountryConfigurations,
} from "app/configuration";
import i18n from "app/config/i18n";

const { REACT_APP_CONFIG_LIVE } = process.env;

export const ALERT_MEESAGE = "ALERT_MEESAGE";
export const SET_COUNTRIES = "SET_COUNTRIES";
export const SET_PACKAGES = "SET_PACKAGES";
export const SET_BRANDS = "SET_BRANDS";
export const SET_GOVENRATES_AND_DISTRICTS = "SET_GOVENRATES_AND_DISTRICTS";
export const SET_PAYMENT_PROVIDERS = "SET_PAYMENT_PROVIDERS";
export const SET_ALL_PREFERENCES = "SET_ALL_PREFERENCES";
export const SET_PACKAGE_LOADER = "SET_PACKAGE_LOADER";
export const SET_CUSTOM_PACKAGE = "SET_CUSTOM_PACKAGE";
export const SET_CONFIG = "SET_CONFIG";
export const SET_STORES = "SET_STORES";
export const SET_ALLERGIES_SELECTION = "SET_ALLERGIES_SELECTION";
export const SET_BUNDLE_DATA = "SET_BUNDLE_DATA";
export const SET_BUNDLE_FILTER = "SET_BUNDLE_FILTER";
export const SET_BUNDLE_LOADER = "SET_BUNDLE_LOADER";
export const SET_DRIVERS = "SET_DRIVERS";

export const subscriptionStatus = [
  { label: "Pending", value: "Pending" },
  { label: "Ongoing", value: "Ongoing" },
  { label: "Paused", value: "Paused" },
  { label: "Cancelled", value: "Cancelled" },
  { label: "Expired", value: "Expired" },
  { label: "Refund Requested", value: "RefundRequested" },
];
export const Privileges = {
  superAdmin: "SuperAdmin",
  admin: "Admin",
  support: "Support",
  dietitian: "Dietitian",
  marketing: "Marketing",
  centralKitchenManager: "CentralKitchenManager",
  cloudKitchenManager: "CloudKitchenManager",
  centralKitchenStaff: "CentralKitchenStaff",
  logisticManager: "LogisticManager",
  customerServiceAgent: "CustomerServiceAgent",
  customerServiceSupervisor: "CustomerServiceSupervisor",
  menuDeveloper: "MenuDeveloper",
  cloudKitchenStaff: "CloudKitchenStaff",
};
export const OnDemandStatus = {
  Confirmed: "Confirmed",
  Cancelled: "Cancelled",
  ReadyForDelivery: "ReadyForDelivery",
  OutForDelivery: "OutForDelivery",
  DeliveredLate: "DeliveredLate",
  Pending: "Pending",
  Delivered: "Delivered",
};
export const onDemandStatusDropdownTitle: string[] = [
  "Accept Order",
  "Reject Order",
];

export const onDemandAllStatus = {
  Accept: "Accept Order",
  Reject: "Reject Order",
  ReadyForDelivery: "Ready For Delivery",
  OutForDelivery: "Out For Delivery",
  DeliveredLate: "Delivered Late",
};
export const productAllStatus = {
  Unavailable: "Unavailable",
  Masked: "Masked",
  ReadyForSale: "ReadyForSale",
  Outofstock: "Out of stock",
  Notpublished: "Not published",
  Published: "Published",
  Disabled: "Disabled",
};
export const createdByRole = ["Admin", "Client"];

export const subscriptionRequestStatus = [
  { name: i18n.t("common.statuses.dispatch"), id: "Dispatched" },
  { name: i18n.t("common.statuses.failed"), id: "Failed" },
];

export const getActiveConfig = (
  locale?: string
): {
  activeLocale: BackendCountryConfigurations;
  defaultStoreId: number;
  defaultBrand: BackendCountryConfigurations["brands"][0];
} => {
  if (!locale) {
    const countryLocalerString = localStorage.getItem("countryCode");
    if (countryLocalerString) {
      locale = countryLocalerString.toLowerCase();
    }
  }

  const activeConfig: BackendConfigurations =
    REACT_APP_CONFIG_LIVE === "true"
      ? configurationsProduction
      : configurationsStaging;

  // NOTE: for now this is only for kw/ once other locale is enable we need to adjust this.
  const activeLocale: BackendCountryConfigurations =
    activeConfig[locale ?? "kw"];

  const defaultBrand = activeLocale.brands.find(
    (brand) => brand.brandId === activeLocale.defaultBrandId
  );

  const defaultStoreId = defaultBrand.storeid;

  return { activeLocale, defaultStoreId, defaultBrand };
};

interface CreateQueryProps {
  filter: any;
  customFields?: {
    customKey: string;
    newValue: string;
    valueType: "text" | "array";
    secondaryValue?: string;
  }[];
}

export const createQuery = ({ filter, customFields }: CreateQueryProps) => {
  const customFieldsObj = Object.fromEntries(
    customFields?.map((item) => [item.customKey, item]) || []
  );

  return Object.entries(filter)
    .filter(([key, value]) => value)
    .map(([key, value]) => {
      const customObj = customFieldsObj[key];

      if (customObj?.newValue && value) {
        const { newValue, valueType, secondaryValue } = customObj;

        if (valueType === "array" && Array.isArray(value)) {
          return value.map((val) => `${newValue}=${val}`).join("&");
        } else {
          const param = `${newValue}=${value}`;

          if (secondaryValue) {
            return `${param}&${secondaryValue}=${value}`;
          } else {
            return param;
          }
        }
      } else {
        return `${key}=${value}`;
      }
    })
    .join("&");
};
