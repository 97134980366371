import { CreateBlockedListPayload, UpdateBlockedListPayload } from "app/types";
import { AxiosResponse } from "axios";
import axios from "./config";
import { UpdateRecipeNutritionalFactsApiResponse } from "app/types/meals";

export const getDishesAPI = (query?: any) => {
  return axios.get(`/Dishes${query}`);
};

export const getCategoriesAPI = (query: any) =>
  axios.get(`/categories${query}`);

export const createDishAPI = (payload: any) => axios.post(`/Dishes`, payload);

export const updateDishAPI = (dishId: number, payload: any) =>
  axios.put(`/Dishes/${dishId}`, payload);

export const createDishLocaleAPI = (dishId: number, payload: any) =>
  axios.post(`/Dishes${dishId}/localizations`, payload);

export const getPortionsAPI = (query: any) => axios.get(`/portions${query}`);

export const createRecipeAPI = (dishId: number, payload: any) =>
  axios.post(`/dishes/${dishId}/Recipes`, payload);

export const updateRecipeAPI = (
  dishId: number,
  recipeId: number,
  payload: any
) => axios.put(`/dishes/${dishId}/Recipes/${recipeId}`, payload);

export const getDishRecipesAPI = (dishId: number, query?: any) => {
  return axios.get(`/dishes/${dishId}/Recipes${query}`);
};

export const getKitchenSectionAPI = () => {
  return axios.get(`/kitchen-sections`);
};

export const getRecipeAPI = (dishId: number, recipeId?: number) => {
  return axios.get(`/dishes/${dishId}/Recipes/${recipeId}`);
};

export const getItemsAPI = (query: any) => {
  return axios.get(`/items${query}`);
};

export const getSubRecipeAPI = (query: any) => {
  return axios.get(`/subrecipes${query}`);
};

export const getSubRecipeDetailAPI = (subId: number) => {
  return axios.get(`/subrecipes/${subId}`);
};

export const updateSubRecipeAPI = (subId: number, payload: any) => {
  return axios.put(`/subrecipes/${subId}`, payload);
};

export const createSubRecipeAPI = (payload: any) => {
  return axios.post(`/subrecipes`, payload);
};

export const getBlockedListAPI = (storeId: number, query = "") => {
  return axios.get(`/stores/${storeId}/menu-maintenance-schedules${query}`, {
    headers: { Accept: "application/vnd.lightfields.comp.content.api.json" },
  });
};

export const createBlockedListAPI = (
  storeId: number,
  payload: CreateBlockedListPayload
) => {
  return axios.post(`/stores/${storeId}/menu-maintenance-schedules`, payload);
};

export const updateBlockedListAPI = (
  storeId: number,
  payload: UpdateBlockedListPayload
) => {
  return axios.put(
    `/stores/${storeId}/menu-maintenance-schedules/${payload.id}`,
    payload
  );
};
export type UpdateRecipeNutritionalFactsAPIParams = {
  dishId: number;
  recipeId: number;
  payload: {
    fats: number;
    carbohydrate: number;
    proteins: number;
  };
};

export const updateRecipeNutritionalFactsAPI = (
  apiParams: UpdateRecipeNutritionalFactsAPIParams
): Promise<AxiosResponse<UpdateRecipeNutritionalFactsApiResponse>> => {
  return axios.patch<UpdateRecipeNutritionalFactsApiResponse>(
    `/dishes/${apiParams.dishId}/Recipes/${apiParams.recipeId}/nutritional-facts`,
    apiParams.payload
  );
};
