import { combineReducers } from "@reduxjs/toolkit";

import { connectRouter } from "connected-react-router";
import common from "./common";
import auth from "./auth";
import subscriptions from "./subscriptions";
import clients from "./clients";
import address from "./address";
import productions from "./productions";
import catalogs from "./catalogs";
import meals from "./meals";
import deliveryOrder from "./deliveryOrder";
import clientOrder from "./clientOrder";
import createSubscription from "./createSubscription";
import consultation from "./consultation";
import { History } from "history";
import expressOrder from "./expressOrder";
import configuration from "./configuration";
import products from "./products";
import analytics from "../slice/analyticsSlice";
import distribution from "../slice/distributionSlice";
import distributionDriver from "../slice/distributionDriverSlice";
import distributionDeliveries from "../slice/distributionDeliveriesSlice";
import plannedDeliveries from "../slice/plannedDeliveriesSlice";
import customerSupport from "../slice/customerSupportSlice";
import subscriptionsManager from "../slice/subscriptionsManagerSlice";
import clientsSlice from "../slice/clientsSlice";
import  mealsManagerSlice  from "../slice/mealsManagerSlice";

const exportReducers = (history: History) => {
  return combineReducers({
    router: connectRouter(history),
    common,
    auth,
    subscriptions,
    clients,
    clientsSlice,
    address,
    productions,
    catalogs,
    meals,
    deliveryOrder,
    clientOrder,
    createSubscription,
    consultation,
    expressOrder,
    configuration,
    products,
    analytics,
    distribution,
    distributionDriver,
    distributionDeliveries,
    plannedDeliveries,
    customerSupport,
    subscriptionsManager,
    mealsManagerSlice,
  });
};

export default exportReducers;
